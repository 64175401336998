exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about-contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-antidepress-bots-js": () => import("./../../../src/pages/work/antidepress-bots.js" /* webpackChunkName: "component---src-pages-work-antidepress-bots-js" */),
  "component---src-pages-work-beyond-advertising-js": () => import("./../../../src/pages/work/beyond-advertising.js" /* webpackChunkName: "component---src-pages-work-beyond-advertising-js" */),
  "component---src-pages-work-eaternet-js": () => import("./../../../src/pages/work/eaternet.js" /* webpackChunkName: "component---src-pages-work-eaternet-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-pitapat-js": () => import("./../../../src/pages/work/pitapat.js" /* webpackChunkName: "component---src-pages-work-pitapat-js" */),
  "component---src-pages-work-scarcities-js": () => import("./../../../src/pages/work/scarcities.js" /* webpackChunkName: "component---src-pages-work-scarcities-js" */),
  "component---src-pages-work-sdlg-js": () => import("./../../../src/pages/work/sdlg.js" /* webpackChunkName: "component---src-pages-work-sdlg-js" */)
}

